<!-- Finder de Expedientes Traslados -->

<template>
  <div class="exp_M_traslado" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Contenido -->     
      <div class="conflex">
    
        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi       
                v-if="$store.state[padre].estado!= 'nuevo'"
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campos del grid menos el de acciones -->
          <template v-slot:fields_grid="{ Sitem, Sheader }">
            <!-- <div :style="`text-align:${Sheader.align}`"> -->
            {{ Sitem[Sheader.value] }}
            <!-- </div> -->
            <div v-if="Sheader.value=='procedenciaen' && Sitem.name_procedencia" class="light-blue--text text--darken-3"> {{ Sitem.name_procedencia }}</div> 
            <!-- </div> -->
            <div v-if="Sheader.value=='destinoen' && Sitem.name_destino" class="light-blue--text text--darken-3"> {{ Sitem.name_destino }}</div> 
          </template>

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>

          <!-- Slot panel expansible -->
          <template v-slot:expansible_ctrls="{}">             
            <exp_M_traslado_XM
              :padre="stName"
              :accion="accion"
              :accionRow="rowSelected"
              :disparo="disparo"            
              @onEvent="event_expansible">
            </exp_M_traslado_XM>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>      
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const exp_M_traslado_XM = () => plugs.groute("exp_M_traslado_XM.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, btracombi, exp_M_traslado_XM },
    props: {
      padre: { type:String, default: ''},
      id: { type:[Number, String], default: 0},     
      disparoFinder: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'FM' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return { 
        schema:null,             
        api:'exp_Tras_F',
        stName:'stFexp_Tras_F',
        disparo:false,
        accion:'',
        accionRow: {},  
        
        headers: {},        
      };
    },

    methods: {
      ini_data() {      
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // le paso el índice del record detalle que coge del Mto
        this.$store.commit('data2State', { modulo:this.stName, prop:'no_getRecordsAPI', value:3});

        // entorno
        this.Entorno.grid.autoload= true;        
        this.Entorno.grid.row.expansible= true;
        this.Entorno.header.header= false;
        this.Entorno.grid.bind["items-per-page"] = -1;

        this.headers = {
          header:[
            { text: "Medio", value: "medio" },
            { text: "Salida", value: "salidafhhr" },
            { text: "Procedencia", value: "procedenciaen" },                    
            { text: "Llegada", value: "llegadafhhr" },
            { text: "Destino", value: "destinoen" }                 
          ]
        };

        this.Entorno.grid.headers = this.set_headers();      
      },
    


      // devuelvo la fecha en formato visual (sin la hora, sólo la fecha)
      /* get_fecha(date) {
        if (!date) return "";

        // separo la fecha de la hora
        const sep = this.$store.state.separador_fecha;
        const [sdate] = date.split(" ");
        const [ano, mes, dia] = sdate.split("-");

        // devuelvo solo la hora en formato visual
        return `(${dia}${sep}${mes}${sep}${ano})`;
      } */
    }
  };
</script>
